import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';



const YouTubeVideoList = () => {
  const [videos, setVideos] = useState([]);
  const predefinedTerm = 'girl burp -periscope'; // Define your predefined search term here

  useEffect(() => {
    // Define your API key and YouTube Data API endpoint
    const apiKey = 'AIzaSyAsrL1_0slmtNQ-aHi-4yGWgY3Kf20f_tg';
    const apiUrl = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&part=snippet&type=video&q=${predefinedTerm}&maxResults=5`;

    axios.get(apiUrl)
      .then((response) => {
        setVideos(response.data.items);
      })
      .catch((error) => {
        console.error('Error fetching YouTube videos:', error);
      });
  }, []);

  // Helper function to get the date two weeks ago in ISO format
  const getTwoWeeksAgo = () => {
    const today = new Date();
    today.setDate(today.getDate() - 14);
    return today.toISOString();
  };

  return (
    <Container>
      <h2 className="mt-4 mb-3">Top 5 YouTube Videos in the Last 2 Weeks for girls burping</h2>
      <Row>
        {videos.map((video) => (
          <Col key={video.id.videoId} xs={12} className="mb-4" style={{ marginTop: '50px' }}>
            <h3>{video.snippet.title}</h3>
            <p>{video.snippet.description}</p>
            <div className="video-item" style={{ maxWidth: '500px', maxHeight: '281px', }}>
                <iframe
                    title={video.snippet.title}
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${video.id.videoId}`}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default YouTubeVideoList;
