import React from 'react';
import Navvy from './components/Navbar';
import Footer from './components/Footer';
import YouTubeVideoList from './components/List';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './components/About.js';
import './index.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navvy />
        <Routes>
          <Route path="/" element={<YouTubeVideoList />} />
          <Route path="/About" element={<About />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
