import React from 'react';

function Footer() {
  return (
    <footer>
      <p class="text-center">@2023 Reighbauch</p>
    </footer>
  )
}

export default Footer;