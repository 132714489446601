import React from 'react';
import { Container } from 'react-bootstrap';

function About() {
    return (
      <>
        <Container>
            <h2 className="mt-4 mb-3">About</h2>
            <h3>What this website is:</h3>
            <p>This website is an idea I had meant to be used as a stretch of both my react and webhosting skills</p>
            <div></div>
            <h3>What data we obtain and store:</h3>
            <p>This website obtains and stores zero data. Youtube videos are embedded directly from youtube and are retrieved upon loading the page.</p>
        </Container>
      </>
    );
  }
  

  export default About;